const FourDaysBtn = (props: any) => {
  return (
    <button
      className="4-days-btn login-btn"
      onClick={props.trigger}
      style={{
        padding: "15px 20px",
        fontSize: "16px",
        margin: 0,
        textTransform: "uppercase",
      }}
    >
      4 DAYS
    </button>
  );
};

export default FourDaysBtn;
