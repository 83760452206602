import React from "react";
import { AdLevelTotals } from "../../types/Optimize";
import Column from "./Column";
const RowAd = (props: { data: AdLevelTotals }) => {
  let barva = "";
  let fontWeight = 400;

  if (props.data.ROAS > 3.99) {
    barva = "#5c9e07";
    fontWeight = 600;
  }
  if (props.data.ROAS < 2.2) {
    barva = "#bb3f3f";
    fontWeight = 600;
  }

  return (
    <div className="table-row-ad row">
      <Column
        data={props.data.adName}
        campaign={true}
        barva={barva}
        fontWeight={fontWeight}
      />
      <Column data={props.data.totalOrderCount} />
      <Column data={props.data.totalSpend.toFixed(2)} />
      <Column data={props.data.totalOrderValue.toFixed(2)} />
      <Column data={props.data.ROAS ? props.data.ROAS.toFixed(2) : 0} />
      <Column data={props.data.CPP ? props.data.CPP.toFixed(2) : 0} />
    </div>
  );
};

export default RowAd;
