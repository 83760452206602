import React from "react";

const Header = () => {
  return (
    <div className="table-header-row row">
      <div className="table-header-col campaign">Campaign</div>
      <div className="table-header-col">Purchase</div>
      <div className="table-header-col">Spend</div>
      <div className="table-header-col">Value €</div>
      <div className="table-header-col">ROAS</div>
      <div className="table-header-col">CPP</div>
    </div>
  );
};

export default Header;
