import React, { useEffect } from "react";
const ABtest = () => {
  const [today, setToday] = React.useState<any>(null);
  const [fDays, setfDays] = React.useState<any>(null);
  const [month, setMonth] = React.useState<any>(null);
  const [week, setWeek] = React.useState<any>(null);
  const fetchABStats = (start: string, end: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl =
          "https://sk.pruvida.co/wp-json/pruvida-analitika-api/v1/ab-purchases";
        const apiKey =
          "piFnKm8e1x0Uir9DbZsnaHVpNLyV1pU4JO0Abo3kcZ2Flm2JqDyExrCb9y8tevL09iM9T6xnJOFFYz1H1kZvtDN81rz4AXSgOyzVKYe85EHoot1EvLzmVMrol6mXXKWh";
        const reqUrl = `${apiUrl}?api_key=${apiKey}&s=${start}&e=${end}`;
        const response = await fetch(reqUrl);
        const data = await response.json();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    const todaysDate = new Date();
    const todayInString = `${todaysDate.getFullYear()}-${
      todaysDate.getMonth() + 1
    }-${todaysDate.getDate()}`;

    const fourDaysAgo = Date.now() - 4 * 24 * 60 * 60 * 1000;
    const fourDaysAgoDate = new Date(fourDaysAgo);
    const fourDaysAgoInString = `${fourDaysAgoDate.getFullYear()}-${
      fourDaysAgoDate.getMonth() + 1
    }-${fourDaysAgoDate.getDate()}`;

    const monthAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
    const monthAgoDate = new Date(monthAgo);
    const monthAgoInString = `${monthAgoDate.getFullYear()}-${
      monthAgoDate.getMonth() + 1
    }-${monthAgoDate.getDate()}`;

    const weekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    const weekAgoDate = new Date(weekAgo);
    const weekAgoInString = `${weekAgoDate.getFullYear()}-${
      weekAgoDate.getMonth() + 1
    }-${weekAgoDate.getDate()}`;
    //Fetch data for today
    fetchABStats(`${todayInString} 00:00:00`, `${todayInString} 23:59:59`)
      .then((data) => setToday(data))
      .catch((err) => console.log(err));
    //Fetch data for 4 days ago
    fetchABStats(`${fourDaysAgoInString} 00:00:00`, `${todayInString} 23:59:59`)
      .then((data) => setfDays(data))
      .catch((err) => console.log(err));

    //Fetch data for week ago
    fetchABStats(`${weekAgoInString} 00:00:00`, `${todayInString} 23:59:59`)
      .then((data) => setWeek(data))
      .catch((err) => console.log(err));
    //Fetch data for month ago
    fetchABStats(`${monthAgoInString} 00:00:00`, `${todayInString} 23:59:59`)
      .then((data) => setMonth(data))
      .catch((err) => console.log(err));
  }, []);

  const makeTable = (title: string, data: any) => {
    return (
      <div className="table-container">
        <h2>{title}</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>A</th>
              <th>B</th>
              <th>Δ %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="first-td">Views</td>
              <td>{data.views.a}</td>
              <td>{data.views.b}</td>
              <td>
                {(
                  Math.abs(
                    (Number(data.views.a) - Number(data.views.b)) /
                      (Number(data.views.a) + Number(data.views.b))
                  ) * 100
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="first-td">Purchases</td>
              <td>{Number(data.purchases.a)}</td>
              <td>{Number(data.purchases.b)}</td>
              <td>
                {(
                  Math.abs(
                    (Number(data.purchases.a) - Number(data.purchases.b)) /
                      (Number(data.purchases.a) + Number(data.purchases.b))
                  ) * 100
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="first-td">Value</td>
              <td>{data.values.a.toFixed(2)}</td>
              <td>{data.values.b.toFixed(2)}</td>
              <td>
                {(
                  Math.abs(
                    (Number(data.values.a) - Number(data.values.b)) /
                      (Number(data.values.a) + Number(data.values.b))
                  ) * 100
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="first-td">Conversion</td>
              <td>
                {(
                  (Number(data.purchases.a) / Number(data.views.a)) *
                  100
                ).toFixed(2)}
              </td>
              <td>
                {(
                  (Number(data.purchases.b) / Number(data.views.b)) *
                  100
                ).toFixed(2)}
              </td>
              <td>
                {" "}
                {Math.abs(
                  (Number(data.purchases.a) / Number(data.views.a)) * 100 -
                    (Number(data.purchases.b) / Number(data.views.b)) * 100
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="table-super-container">
      {today && makeTable("TODAY", today)}
      {fDays && makeTable("LAST 4 DAYS", fDays)}
      {week && makeTable("LAST WEEK", week)}
      {month && makeTable("LAST 30 DAYS", month)}
    </div>
  );
};

export default ABtest;
