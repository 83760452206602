import "./style/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Optimize from "./pages/Optimize";
import Analytics from "./pages/Analytics";
import Navbar from "./components/Navbar";
import ABtest from "./pages/ABTest";
import LoginPage from "./pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import { useEffect, useState } from "react";
import StoresPage from "./pages/StoresPage";
import CreateCurrencyPage from "./pages/CreateCurrencyPage";
import UpdateCurrencyPage from "./pages/UpdateCurrencyPage";
import UniversalOptimizePage from "./pages/UniversalOptimizePage";
import UniversalParent from "./pages/UniversalParent";

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("AccessToken");
    if (token) {
      setLoggedIn(true);
    }
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar loggedIn={loggedIn} />
        <Routes>
          <Route
            path="/"
            element={
              <LoginPage loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route path="/optimize" element={<Optimize />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/abtest" element={<ABtest />} />
            <Route path="/create-store" element={<StoresPage />} />
            <Route path="/create-currency" element={<CreateCurrencyPage />} />
            <Route path="/update-currency" element={<UpdateCurrencyPage />} />

            <Route
              path="/universal/optimize"
              element={<UniversalOptimizePage />}
            />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
