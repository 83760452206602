import { queryDate } from "../../../types/Store";
import Day from "./Day";
import Month from "./Month";
import Year from "./Year";

const Select = (props: { state: queryDate; stateSet: any }) => {
  return (
    <div className="select-super-container">
      <Day state={props.state} stateSet={props.stateSet} />
      <Month state={props.state} stateSet={props.stateSet} />
      <Year state={props.state} stateSet={props.stateSet} />
    </div>
  );
};

export default Select;
