import React, { useState } from "react";
import { AdsetLevelTotals, CampaignLevelTotals } from "../../types/Optimize";
import Column from "./Column";
import RowAdset from "./RowAdset";

const RowCampaign = (props: { data: CampaignLevelTotals }) => {
  const [showAdsets, setShowAdsets] = useState<boolean>(false);

  const toggleAdsets = (e: any) => {
    setShowAdsets(!showAdsets);
  };

  let barva = "";
  let fontWeight = 400;

  if (props.data.ROAS > 3.99) {
    barva = "#5c9e07";
    fontWeight = 600;
  }
  if (props.data.ROAS < 2.2) {
    barva = "#bb3f3f";
    fontWeight = 600;
  }

  const makeAdsetRows = (adsets: AdsetLevelTotals[]): React.ReactElement[] => {
    const result: React.ReactElement[] = [];
    adsets.map((adset) => {
      const tmp = <RowAdset data={adset} />;
      result.push(tmp);
    });

    return result;
  };

  return (
    <div className="table-row-campaign-container">
      <div className="table-row-campaign row" onClick={toggleAdsets}>
        <Column
          data={props.data.campaignName}
          campaign={true}
          leftBorder={showAdsets}
        />
        <Column data={props.data.totalOrderCount} />
        <Column data={props.data.totalSpend.toFixed(2)} />
        <Column data={props.data.totalOrderValue.toFixed(2)} />
        <Column
          data={props.data.ROAS ? props.data.ROAS.toFixed(2) : 0}
          barva={barva}
          fontWeight={fontWeight}
        />
        <Column data={props.data.CPP ? props.data.CPP.toFixed(2) : 0} />
      </div>
      <div className={`t-row-c-adset-container ${!showAdsets ? "skrito" : ""}`}>
        {makeAdsetRows(props.data.adsets)}
      </div>
    </div>
  );
};

export default RowCampaign;
