import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { proxy } from "../env/proxy";

const LoginPage = (props: { loggedIn: boolean; setLoggedIn: any }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [btn, setBtn] = useState<boolean>(false);
  const [loginErr, setLoginErr] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("AccessToken");
    if (token) {
      navigate("/optimize");
    }
    btnGuard();
  }, []);

  const btnGuard = () => {
    if (password && password.length > 6 && email && email.length > 5) {
      setBtn(true);
    } else {
      setBtn(true);
    }
  };
  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
    btnGuard();
  };

  const onPasswordChange = (e: any) => {
    setPassword(e.target.value);
    btnGuard();
  };

  const onClickson = async () => {
    try {
      setLoginErr(false);
      const url = `${proxy.url}/login`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: email,
          password,
        }),
      });
      const resData = await response.json();
      console.log(resData);
      if (!resData || resData.success == 0) {
        setLoginErr(true);
      } else {
        localStorage.setItem("AccessToken", resData.creds);
        props.setLoggedIn(true);
        navigate("/optimize");
      }
    } catch (error) {
      setLoginErr(true);
    }
  };
  return (
    <div className="login-content">
      {loginErr ? (
        <div className="login-error">Error: "Could not log in"</div>
      ) : null}
      <div className="login-form-container">
        <img src={"/logo.png"} />

        <div className="login-input-container">
          <input type="email" placeholder="Email" onChange={onEmailChange} />
        </div>
        <div className="login-input-container">
          <input
            type="password"
            placeholder="Password"
            onChange={onPasswordChange}
          />
        </div>
        <button className="login-btn" disabled={!btn} onClick={onClickson}>
          Log In
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
