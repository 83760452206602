import React from "react";
import { AdLevelTotals } from "../../types/Optimize";

const Column = (props: {
  data: string | number;
  campaign?: boolean;
  barva?: string;
  fontWeight?: number;
  leftBorder?: boolean;
}) => {
  return (
    <div
      style={{
        color: props.barva ? props.barva : "",
        fontWeight: props.fontWeight ? props.fontWeight : "",
        borderLeft: props.leftBorder ? "4px solid #5c9e07" : "",
      }}
      className={`table-value-col ${props.campaign ? "campaign" : ""}`}
    >
      {props.data}
    </div>
  );
};

export default Column;
