import { useEffect, useState } from "react";
import LoadButton from "../components/inputs/LoadButton";
import SelectContainer from "../components/inputs/SelectContainer";
import Table from "../components/totals/Table";
import { proxy } from "../env/proxy";
import { queryDate } from "../types/Store";
import {
  AnalyticsChartData,
  AnalyticsChartDataNew,
  AnalyticsChartDataProcessed,
  TotalsResponseObject,
} from "../types/Totals";
import ChartContainer from "../components/charts/ChartContainer";
import { useNavigate } from "react-router-dom";
import { redirectUnauthorizedToLogin } from "../services/redirect.service";

const Analytics = () => {
  const currentDate = new Date(Date.now());
  const currentStateStart: queryDate = {
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  };
  const navigate = useNavigate();
  const [start, setStart] = useState<queryDate>(currentStateStart);
  const [end, setEnd] = useState<queryDate>(currentStateStart);
  const [period, setPeriod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableLoad, setDisableLoad] = useState<boolean>(false);
  const [data, setData] = useState<TotalsResponseObject>({
    totals: [],
    cumulativeTotals: {
      storeId: 0,
      storeName: "Total",
      orderCount: 0,
      orderValue: 0,
      spend: 0,
      ROAS: 0,
      CPP: 0,
      itemsCount: 0,
    },
  });
  const [reqError, setReqError] = useState<string>("");

  //CHART DATA STATE
  const [chartData, setChartData] = useState<AnalyticsChartDataProcessed[]>([]);
  const [chartDataPrev, setChartDataPrev] = useState<
    AnalyticsChartDataProcessed[]
  >([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [chartError, setChartError] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    const endDate = { ...currentStateStart };

    /*  fetchTotalsData(currentStateStart, endDate).then((res) => {
      console.log(res);
      setData(res);
      setLoading(false);
    }); */

    fetchCombinedTotalsData(currentStateStart, endDate).then((res) => {
      console.log(res);
      setData(res);
      setLoading(false);
    });

    loadChartData(); /* 
    const prevStart = { ...currentStateStart };
    const prevEnd = { ...currentStateStart };
    //FIX FIRST DAY IN MONTH
    const monthWith30Days = [4, 6, 9, 11];
    //IF 1st of march, last day is 28th FEBRUARY

    //FIRST TRY SETTING START END END DATE FOR TODAY - NUMBER MILISIECONDS IN DAY
    if (prevStart.day === 1) {
      prevStart.month = prevStart.month - 1;
      prevStart.day = 31;
    }
    prevStart.day = prevStart.day - 1;
    prevEnd.day = prevEnd.day - 1; */
    const today = new Date(Date.now()).setHours(0, 0, 0, 0);
    const startDay = new Date(today - 24 * 60 * 60 * 1000);
    const endDay = new Date(today - 1);
    const prevStart = {
      day: startDay.getDate(),
      month: startDay.getMonth() + 1,
      year: startDay.getFullYear(),
    };
    const prevEnd = {
      day: endDay.getDate(),
      month: endDay.getMonth() + 1,
      year: endDay.getFullYear(),
    };
    fetchChartDataPeriod(prevStart, prevEnd).then((res) => {
      const tmp = processChartDataNew(res);
      console.log("TMP");
      console.log(res);
      setChartDataPrev(tmp);
    });
  }, []);

  const fetchTotalsData = (
    s: queryDate,
    e: queryDate | null
  ): Promise<TotalsResponseObject> => {
    return new Promise<TotalsResponseObject>(async (resolve, reject) => {
      try {
        if (!localStorage.getItem("AccessToken")) {
          reject("Unauthorized");
        }
        const url = `${proxy.url}/totals`;
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({ start: s, end: e }),

          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${localStorage.getItem("AccessToken")}`,
          },
        });

        redirectUnauthorizedToLogin(navigate, response);
        /* if (response.status === 401) {
          localStorage.removeItem("AccessToken");
        } */

        const processed: TotalsResponseObject = await response.json();

        resolve(processed);
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchUniversalTotalsData = (
    s: queryDate,
    e: queryDate | null
  ): Promise<TotalsResponseObject> => {
    return new Promise<TotalsResponseObject>(async (resolve, reject) => {
      try {
        if (!localStorage.getItem("AccessToken")) {
          reject("Unauthorized");
        }
        const url = `${proxy.url}/universal/totals`;
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({ start: s, end: e }),

          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${localStorage.getItem("AccessToken")}`,
          },
        });
        redirectUnauthorizedToLogin(navigate, response);

        const processed: TotalsResponseObject = await response.json();

        resolve(processed);
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchCombinedTotalsData = async (
    s: queryDate,
    e: queryDate
  ): Promise<TotalsResponseObject> => {
    try {
      const result: TotalsResponseObject = {
        totals: [],
        cumulativeTotals: {
          storeId: 0,
          storeName: "Total",
          orderCount: 0,
          orderValue: 0,
          spend: 0,
          ROAS: 0,
          CPP: 0,
          itemsCount: 0,
        },
      };

      //FETCH NORMAL STORE TOTALS
      try {
        const totalsData = await fetchTotalsData(s, e);
        result.totals = [...totalsData.totals];
        result.cumulativeTotals = { ...totalsData.cumulativeTotals };
      } catch (error) {
        console.log(error);
      }

      //FETCH UNIVERSAL STORE TOTALS
      try {
        const universalTotalsData = await fetchUniversalTotalsData(s, e);
        result.totals = [...result.totals, ...universalTotalsData.totals];
        result.cumulativeTotals.orderCount =
          result.cumulativeTotals.orderCount +
          universalTotalsData.cumulativeTotals.orderCount;
        result.cumulativeTotals.orderValue =
          result.cumulativeTotals.orderValue +
          universalTotalsData.cumulativeTotals.orderValue;
        result.cumulativeTotals.spend =
          result.cumulativeTotals.spend +
          universalTotalsData.cumulativeTotals.spend;
        //CALC NEW CUMULATIVE ROAS
        result.cumulativeTotals.ROAS =
          result.cumulativeTotals.orderValue / result.cumulativeTotals.spend;

        //CALC NEW CUMULATIVE CPP
        result.cumulativeTotals.CPP =
          result.cumulativeTotals.spend / result.cumulativeTotals.orderCount;

        result.cumulativeTotals.itemsCount =
          result.cumulativeTotals.itemsCount +
          universalTotalsData.cumulativeTotals.itemsCount;
      } catch (error) {
        console.log(error);
      }
      //SORT BY ORDER VALUE DESC
      result.totals.sort((a, b) => {
        return b.orderValue - a.orderValue;
      });
      return result;
    } catch (error) {
      console.log(error);
      const result: TotalsResponseObject = {
        totals: [],
        cumulativeTotals: {
          storeId: 0,
          storeName: "Total",
          orderCount: 0,
          orderValue: 0,
          spend: 0,
          ROAS: 0,
          CPP: 0,
          itemsCount: 0,
        },
      };

      return result;
    }
  };

  const onClickLoad = async () => {
    try {
      setLoading(true);

      let endDate;
      const startDate = start;
      if (period) {
        endDate = end;
      } else {
        endDate = { ...startDate };
      }

      //Fetch Totals Data
      //const responseData = await fetchTotalsData(startDate, endDate);->JUST STORE TOTALS
      const responseData = await fetchCombinedTotalsData(startDate, endDate);
      setData(responseData);
      //Fetch Chart Data
      /* const chartData = await fetchChartDataPeriod(startDate, endDate);
      setChartData(processChartData(chartData)); */
      //Fetch Chart Data Prev
      /* const prevStartDate = { ...startDate };
      const prevEndDate = { ...endDate };
      prevStartDate.day = prevStartDate.day - 1;
      prevEndDate.day = prevEndDate.day - 1; */
      /*  const chartDataPrev = await fetchChartDataPeriod(
        prevStartDate,
        prevEndDate
      );
      
      setChartDataPrev(processChartData(chartDataPrev)); */

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setReqError('Error: "Could not load data"');
    }
  };

  const loadChartData = async () => {
    try {
      setChartLoading(true);
      const url = `${proxy.url}/marketing`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
      });
      redirectUnauthorizedToLogin(navigate, response);

      const processed: AnalyticsChartData[] = await response.json();

      setChartData(processChartData(processed));
      setChartLoading(false);
    } catch (error) {
      setChartError("Error: Could not load data");
      setChartLoading(false);
    }
  };

  const fetchChartDataPeriod = (
    s: queryDate,
    e: queryDate | null
  ): Promise<AnalyticsChartDataNew[]> => {
    return new Promise<AnalyticsChartDataNew[]>(async (resolve, reject) => {
      try {
        if (!localStorage.getItem("AccessToken")) {
          reject("Unauthorized");
        }
        const url = `${proxy.url}/marketing`;
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({ start: s, end: e }),

          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${localStorage.getItem("AccessToken")}`,
          },
        });
        redirectUnauthorizedToLogin(navigate, response);

        const processed: AnalyticsChartDataNew[] = await response.json();

        resolve(processed);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getChartDataTime = (date: string): string => {
    const time = new Date(date);
    const hours = time.getHours();
    //return (hours + 2).toString();
    return hours.toString();
  };
  const processChartData = (
    data: AnalyticsChartData[]
  ): AnalyticsChartDataProcessed[] => {
    const result: AnalyticsChartDataProcessed[] = [];
    data.map((i) => {
      const tmp: AnalyticsChartDataProcessed = {
        time: getChartDataTime(i.time),
        spend: i.spend,
        roas: i.revenue / i.spend,
        revenue: i.revenue,
      };
      result.push(tmp);
    });
    return result;
  };

  const processChartDataNew = (
    data: AnalyticsChartDataNew[]
  ): AnalyticsChartDataProcessed[] => {
    const result: AnalyticsChartDataProcessed[] = [];
    data.map((i) => {
      const tmp: AnalyticsChartDataProcessed = {
        time: getChartDataTime(i.mtda_time),
        spend: i.mtda_spend,
        roas: i.mtda_revenue / i.mtda_spend,
        revenue: i.mtda_revenue,
      };
      result.push(tmp);
    });
    return result;
  };

  return (
    <div className="content">
      <div className="page-level-input-container">
        <SelectContainer
          period={period}
          periodSet={setPeriod}
          start={start}
          end={end}
          setEnd={setEnd}
          setStart={setStart}
        />
        <div className="load-and-4days-btn-container">
          <LoadButton trigger={onClickLoad} disabled={disableLoad} />
        </div>
      </div>
      {reqError != "" ? <div className="err-div">{`${reqError}`}</div> : ""}
      {loading ? (
        <div className="loading-div">
          <img src="/logo512.png"></img>
          Loading
        </div>
      ) : (
        ""
      )}

      <div className="table-div">
        {data &&
        data.totals &&
        data.cumulativeTotals &&
        data.totals.length > 0 &&
        !loading ? (
          <Table data={data} />
        ) : (
          ""
        )}
      </div>
      <ChartContainer data={chartData} dataPrev={chartDataPrev} />
    </div>
  );
};

export default Analytics;
