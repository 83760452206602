import React, { useEffect } from "react";
import CurrencySelect from "../components/stores/CurrencySelect";
import TextInput from "../components/stores/TextInput";
import { proxy } from "../env/proxy";
import { Currency } from "../types/Currency";
import { redirectUnauthorizedToLogin } from "../services/redirect.service";
import { useNavigate } from "react-router-dom";

const StoresPage = () => {
  const navigate = useNavigate();
  const [storeName, setStoreName] = React.useState<string | null>(null);
  const [storeUrl, setStoreUrl] = React.useState<string | null>(null);
  const [storeAdAccount, setStoreAdAccount] = React.useState<string | null>(
    null
  );
  const [storeCurrency, setStoreCurrency] = React.useState<number | null>(null);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [missingData, setMissingData] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [reqError, setReqError] = React.useState<boolean>(false);
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await fetch(`${proxy.url}/currencies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
      });
      redirectUnauthorizedToLogin(navigate, response);
      const processed = await response.json();
      setCurrencies(processed);
    } catch (error) {
      console.log(error);
    }
  };
  const createStore = async () => {
    try {
      setSuccess(false);
      if (!storeName || !storeUrl || !storeAdAccount || !storeCurrency) {
        setMissingData(true);
        return;
      }
      setMissingData(false);
      setLoading(true);

      const url = `${proxy.url}/stores`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
        body: JSON.stringify({
          name: storeName,
          url: storeUrl,
          adAccount: storeAdAccount,
          adAccessToken: "",
          wcApiKey: "",
          currency: storeCurrency,
        }),
      });
      redirectUnauthorizedToLogin(navigate, response);
      const processed = await response.json();
      if (processed.error) {
        setReqError(true);
        setLoading(false);
        return;
      }
      setReqError(false);
      setLoading(false);
      setSuccess(true);

      console.log(processed);
    } catch (error) {
      console.log(error);

      setLoading(false);
      setReqError(true);
    }
  };

  return (
    <div className="content currency-page">
      {missingData ? <h4>Vnesi vse podatke!</h4> : ""}
      {success ? <h4>Valuta je posodobljena!</h4> : ""}
      {reqError ? <h4>Prislo je do napake.</h4> : ""}
      {loading ? <h4>Ustvarjanje valute...</h4> : ""}
      <h1>Dodaj novo trgovino</h1>
      <div className="input-super-conatiner store">
        <TextInput
          setState={setStoreName}
          placeholder="Ime trgovine"
          title="Ime trgovine"
          subText="Primer: Pruvida PL"
        />
        <TextInput
          setState={setStoreUrl}
          placeholder="URL trgovine"
          title="URL trgovine"
          subText="Primer: https://pl.pruvida.co"
        />
        <TextInput
          setState={setStoreAdAccount}
          placeholder="FB AdAccount"
          title="FB AdAccount"
          subText="Primer: 123456789"
        />
        <h4>Izberi valuto</h4>
        <CurrencySelect data={currencies} setCurrency={setStoreCurrency} />
        <br></br>
      </div>
      <div className="input-container">
        <button className="login-btn" onClick={createStore}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default StoresPage;
