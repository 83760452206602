import React from "react";
import { NavLink } from "react-router-dom";

const NavMenu = (props: any) => {
  const activeStyle = {
    border: "2px solid #3d5468",
    color: "#0f293e",
    backgroundColor: "#f6f6f6",
  };

  return (
    <div
      className="navbar-menu-container"
      style={{ display: props.show ? "block" : "none" }}
    >
      <div className="navbar-menu">
        <span className="nav-link-container-menu">
          <NavLink
            to="create-store"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Dodaj Trgovino
          </NavLink>
        </span>
        <span className="nav-link-container-menu">
          <NavLink
            to="create-currency"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            dodaj valuto
          </NavLink>
        </span>
        <span className="nav-link-container-menu">
          <NavLink
            to="update-currency"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            posodobi valuto{" "}
          </NavLink>
        </span>
      </div>
    </div>
  );
};

export default NavMenu;
