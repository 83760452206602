import React from "react";
import NumberInput from "../components/stores/NumberInput";
import TextInput from "../components/stores/TextInput";
import { proxy } from "../env/proxy";
import { useNavigate } from "react-router-dom";
import { redirectUnauthorizedToLogin } from "../services/redirect.service";

const CreateCurrencyPage = () => {
  const [currencyName, setCurrencyName] = React.useState<string | null>(null);
  const [currencyRate, setCurrencyRate] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reqError, setReqError] = React.useState<boolean>(false);
  const [reqSuccess, setReqSuccess] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const createCurrency = async () => {
    try {
      if (!currencyName || !currencyRate) return;
      setLoading(true);
      setReqError(false);
      setReqSuccess(false);
      const url = `${proxy.url}/currencies`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
        body: JSON.stringify({
          name: currencyName,
          rate: currencyRate,
        }),
      });
      redirectUnauthorizedToLogin(navigate, response);
      const processed = await response.json();

      if (!processed || !processed.id)
        throw new Error("Error creating currency");
      setLoading(false);
      setReqSuccess(true);
      setCurrencyName(null);
      setCurrencyRate(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setReqError(true);
    }
  };
  return (
    <div className="content currency-page">
      <h1>Dodaj novo valuto</h1>
      {reqSuccess ? <h4>Valuta je dodana!</h4> : ""}
      {reqError ? <h4>Prislo je do napake.</h4> : ""}
      {loading ? <h4>Ustvarjanje valute...</h4> : ""}
      <div className="input-super-conatiner">
        <div className="inline">
          <TextInput
            setState={setCurrencyName}
            placeholder="Ime Valute"
            title="Ime Valute"
            subText="Na primer: EUR, USD, GBP, ..."
          />
          <NumberInput
            setState={setCurrencyRate}
            placeholder="Tecaj"
            title="Tecaj Valute"
          />
        </div>
        <button className="login-btn" onClick={createCurrency}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default CreateCurrencyPage;
