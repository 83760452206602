import React from "react";
import { Store } from "../../types/Store";
const StoreSelect = (props: { stores: Store[]; setSelected: any }) => {
  const onStoreChange = (e: any) => {
    props.setSelected(e.target.value);
  };

  const fillOptions = () => {
    const options: React.ReactElement[] = [];
    props.stores.map((store: any) => {
      const tmp: React.ReactElement = (
        <option key={`option-${props.stores.indexOf(store)}`} value={store.id}>
          {store.name}
        </option>
      );
      options.push(tmp);
    });
    return options;
  };
  return (
    <div className="store-select-container">
      <select
        className="store-select"
        onChange={onStoreChange}
        defaultValue={"Select Store"}
      >
        <option disabled key={"pakson"}>
          Select Store
        </option>
        {props.stores && props.stores.length > 0 ? fillOptions() : ""}
      </select>
    </div>
  );
};

export default StoreSelect;
