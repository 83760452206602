import { useEffect, useState } from "react";
import FourDaysBtn from "../components/inputs/FourDaysBtn";
import LoadButton from "../components/inputs/LoadButton";
import SelectContainer from "../components/inputs/SelectContainer";
import StoreSelect from "../components/inputs/StoreSelect";
import TableBody from "../components/optimize/TableBody";
import { proxy } from "../env/proxy";
import { OptimizeResponseData } from "../types/Optimize";
import { queryDate, Store } from "../types/Store";
import { redirectUnauthorizedToLogin } from "../services/redirect.service";
import { useNavigate } from "react-router-dom";

const UniversalOptimizePage = () => {
  const navigate = useNavigate();
  const EmptyData: OptimizeResponseData = {
    campaigns: [],
    googleAds: {
      totalOrdersCount: 0,
      totalOrderValue: 0,
      spend: 0,
      ROAS: 0,
      CPP: 0,
    },
    undefinedOrders: {
      totalOrdersCount: 0,
      totalOrderValue: 0,
      spend: 0,
      ROAS: 0,
      CPP: 0,
    },
    partialyDefinedOrders: [],
  };

  const fetchOptimizeData = (
    s: queryDate,
    e: queryDate | null,
    storeId: number
  ): Promise<OptimizeResponseData> => {
    return new Promise<OptimizeResponseData>(async (resolve, reject) => {
      try {
        if (!localStorage.getItem("AccessToken")) {
          reject("Unauthorized");
        }
        const url = `${proxy.url}/universal/optimize`;
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({ start: s, end: e, store: storeId }),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${localStorage.getItem("AccessToken")}`,
          },
        });
        redirectUnauthorizedToLogin(navigate, response);

        const processed: OptimizeResponseData = await response.json();
        console.log(processed);
        const result: OptimizeResponseData = {
          campaigns: processed.campaigns,
          googleAds: processed.googleAds,
          undefinedOrders: processed.undefinedOrders,
          partialyDefinedOrders: processed.partialyDefinedOrders,
        };
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  const onClick4Days = async () => {
    try {
      const today = new Date(Date.now());
      const reqEnd = {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      };

      const reqStart = {
        day: today.getDate() - 3,
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      };
      setLoading(true);
      const responseData = await fetchOptimizeData(reqStart, reqEnd, store);
      setData(responseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setReqError('Error: "Could not load data"');
    }
  };
  const onClickLoad = async () => {
    try {
      setLoading(true);

      let endDate;
      const startDate = start;
      if (period) {
        endDate = end;
      } else {
        endDate = { ...startDate };
      }

      const responseData = await fetchOptimizeData(startDate, endDate, store);
      setData(responseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setReqError('Error: "Could not load data"');
    }
  };

  const fetchStores = async () => {
    if (!localStorage.getItem("AccessToken")) {
      return [];
    }
    const url = `${proxy.url}/universal/stores`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${localStorage.getItem("AccessToken")}`,
      },
    });

    redirectUnauthorizedToLogin(navigate, response);
    const resData = await response.json();

    setStores(resData);
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const currentDate = new Date(Date.now());
  const currentStateStart: queryDate = {
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  };
  const [reqError, setReqError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [start, setStart] = useState<queryDate>(currentStateStart);
  const [end, setEnd] = useState<queryDate>(currentStateStart);
  const [data, setData] = useState<OptimizeResponseData>(EmptyData);
  const [store, setStore] = useState<number>(1);
  const [disableLoad, setDisableLoad] = useState<boolean>(false);
  const [period, setPeriod] = useState(false);
  const [stores, setStores] = useState<Store[]>([]);
  return (
    <div className="content">
      <div className="page-level-input-container">
        <StoreSelect stores={stores} setSelected={setStore} />
        <SelectContainer
          period={period}
          periodSet={setPeriod}
          start={start}
          end={end}
          setEnd={setEnd}
          setStart={setStart}
        />
        <div className="load-and-4days-btn-container">
          <LoadButton trigger={onClickLoad} disabled={disableLoad} />
          <FourDaysBtn trigger={onClick4Days} />
        </div>
      </div>
      {reqError != "" ? <div className="err-div">{`${reqError}`}</div> : ""}
      {loading ? (
        <div className="loading-div">
          <img src="/logo512.png"></img>
          Loading
        </div>
      ) : (
        ""
      )}
      {!loading &&
      data.googleAds &&
      data.undefinedOrders &&
      data.campaigns &&
      data.partialyDefinedOrders ? (
        <TableBody data={data} />
      ) : (
        ""
      )}
    </div>
  );
};

export default UniversalOptimizePage;
