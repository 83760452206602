import React from "react";
import { NavLink } from "react-router-dom";
import NavMenu from "./navbar/NavMenu";
const Navbar = (props: { loggedIn: boolean }) => {
  const activeStyle = {
    color: "#f6f6f6",
  };
  const loggedIn = props.loggedIn;
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return loggedIn ? (
    <div className="navbar">
      <NavMenu show={showMenu} />
      <div className="nav-menu-icon">
        <img src="/hb-menu.png" onClick={toggleMenu} />
      </div>
      <div className="nav-logo-container">2BS</div>

      <div className="nav-link-super-container">
        <span className="nav-link-container">
          <NavLink
            to="optimize"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            /* className={({ isActive }) => (isActive ? "nav-link-active" : "")} */
          >
            Optimize{" "}
          </NavLink>
        </span>
        <span className="nav-link-container">
          <NavLink
            to="analytics"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            analytics{" "}
          </NavLink>
        </span>
        <span className="nav-link-container">
          <NavLink
            to="universal/optimize"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            universal{" "}
          </NavLink>
        </span>
        <span className="nav-link-container">
          <NavLink
            to="abtest"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            ab test{" "}
          </NavLink>
        </span>
      </div>

      <div className="nav-select-container"></div>
    </div>
  ) : (
    <div></div>
  );
};

export default Navbar;
