import { ReactElement, useState } from "react";
import {
  CampaignLevelTotals,
  OptimizeResponseData,
} from "../../types/Optimize";
import RowCampaign from "./RowCampaign";
import Header from "./Header";
import OtherDataRow from "./OtherDataRow";

const TableBody = (props: { data: OptimizeResponseData }) => {
  const [selectedAdAccount, setSelectedAdAccount] = useState<string>(
    props.data.campaigns.length > 0 ? props.data.campaigns[0].adAccount : ""
  );
  const makeCampaigns = (data: CampaignLevelTotals[]) => {
    const result: ReactElement[] = [];
    data.map((camapign) => {
      result.push(<RowCampaign data={camapign} />);
    });
    return result;
  };
  const onClickSetAdAccount = (accountId: string) => {
    setSelectedAdAccount(accountId);
  };
  return (
    <div className="table-div">
      <span className="ad-account-title">IZBERI AD-ACCOUNT:</span>
      <div className="ad-account-switch-container">
        {props.data.campaigns.map((campaign) => {
          return (
            <div
              className={`ad-account-switch ${
                selectedAdAccount === campaign.adAccount
                  ? "ad-account-switch-active"
                  : ""
              }`}
            >
              <span
                className={`store-select `}
                onClick={() => {
                  onClickSetAdAccount(campaign.adAccount);
                }}
              >
                {campaign.adAccount.replace("act_", "")}
              </span>
            </div>
          );
        })}
      </div>
      <Header />
      {/* ADD DIV TO SWITCH BETWEEN AD ACCOUTNS */}
      <div className="ad-account-super-container">
        {props.data.campaigns.map((campaign) => {
          return (
            <div
              className="ad-account-container"
              style={{
                display:
                  selectedAdAccount === campaign.adAccount ? "block" : "none",
              }}
            >
              {makeCampaigns(campaign.data)}
            </div>
          );
        })}
      </div>
      {/* <div className="table-data">
        {makeCampaigns(props.data.matchedOrders)}
      </div> */}
      {/* ADD DIV TO SWITCH BETWEEN AD ACCOUTNS */}
      <span className="ads-table-splitter">NEPOVEZANI OGLASI</span>
      <div className="table-data">
        {makeCampaigns(props.data.partialyDefinedOrders)}
      </div>
      <OtherDataRow data={props.data.googleAds} name={"Google Ads"} />
      <OtherDataRow
        data={props.data.undefinedOrders}
        name={"Undefined Orders"}
      />
    </div>
  );
};

export default TableBody;
